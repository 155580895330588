

.dashboard-container {
    padding: 20px;
}


.dashboard-heading {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}

.dashboard-subheading {
    margin-top: 15px;
    margin-bottom: 15px;
}

.table {
    overflow-x: auto;
}


@media (max-width: 768px) {
    .dashboard-heading,
    .dashboard-subheading {
        font-size: 1.5rem;
    }
    .dashboard-container {
        padding: 10px;
    }
}

.card-columns {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
}
